import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'

const initialState = {
  startDate: moment().subtract(1, "months").toDate(),
  endDate: moment().toDate(),
  searching: false
}

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setClear: (state) => {
      state.startDate = moment().subtract(1, "months").toDate()
      state.endDate = moment().toDate()
      state.searching = true
    },
    setSearching: (state, action) => {
      state.searching = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    }
  }
})

export const { setSearching, setClear, setStartDate, setEndDate } = statisticsSlice.actions

export const getStartDate = (state) => { return state.statistics.startDate }
export const getEndDate = (state) => { return state.statistics.endDate }
export const isSearching = (state) => { return state.statistics.searching }

export default statisticsSlice.reducer

import React from 'react'
import { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import ImageResize from 'quill-image-resize'
import API from '../../util/api'

const CustomUndo = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
      <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
    </svg>
  )
}

// Redo button icon component for Quill editor
const CustomRedo = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
      <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
    </svg>
  )
}

function undoChange () {
  this.quill.history.undo()
}
function redoChange () {
  this.quill.history.redo()
}


const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)

const Font = Quill.import('formats/font')
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida']
Quill.register(Font, true)

Quill.register("modules/imageUploader", ImageUploader)
Quill.register('modules/imageResize', ImageResize)

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  imageResize: {
    modules: ['Resize', 'DisplaySize', 'Toolbar']
  },
  imageUploader: {
    upload: async (file) => {
      const formData = new FormData()
      formData.append('image', file)
      const IMG_URL = await API.post('/api/editor', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => { return res })
      return `${process.env.REACT_APP_EDITOR_URL}${IMG_URL}`
    }
  }
}

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
]

// Quill Toolbar component
export const QuillToolbar = () => {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="note-btn ql-header" defaultValue="3">
          <option value="1">제목 1</option>
          <option value="2">제목 2</option>
          <option value="3">제목 3</option>
          <option value="4">제목 4</option>
          <option value="5">제목 5</option>
          <option value="6">제목 6</option>
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" className="note-btn ql-bold" aria-label="굵게" title="굵게" />
        <button type="button" className="note-btn ql-italic" aria-label="기울임꼴" title="기울임꼴" />
        <button type="button" className="note-btn ql-underline" aria-label="밑줄" title="밑줄" />
        <button type="button" className="note-btn ql-strike" aria-label="취소선" title="취소선" />
      </span>
      <span className="ql-formats">
        <button
          type="button"
          className="note-btn ql-list"
          value="ordered"
          aria-label="번호 매기기"
          title="번호 매개기"
        />
        <button type="button" className="note-btn ql-list" value="bullet" aria-label="글머리 기호" title="글머리 기호" />
        <button type="button" className="note-btn ql-indent" value="-1" aria-label="들여쓰기" title="들여쓰기" />
        <button type="button" className="note-btn ql-indent" value="+1" aria-label="내어쓰기" title="내어쓰기" />
      </span>
      <span className="ql-formats">
        <button type="button" className="note-btn ql-script" value="super" aria-label="위 첨자" title="위 첨자" />
        <button type="button" className="note-btn ql-script" value="sub" aria-label="아래 첨자" title="아래 첨자" />
        <button type="button" className="note-btn ql-blockquote" aria-label="인용구" title="인용구" />
      </span>
      <span className="ql-formats">
        <select className="note-btn ql-align" aria-label="문단 정렬" title="문단 정렬" />
        <select className="note-btn ql-color" aria-label="글자색" title="글자색" />
        <select className="note-btn ql-background" aria-label="배격색" title="배경색" />
      </span>
      <span className="ql-formats">
        <button type="button" className="note-btn ql-link" aria-label="링크" title="링크" />
        <button type="button" className="note-btn ql-image" aria-label="이미지" title="이미지" />
        <button type="button" className="note-btn ql-video" aria-label="동영상" title="동영상" />
        <button type="button" className="note-btn ql-code-block" aria-label="코드" title="코드 블럭" />
      </span>
      <span className="ql-formats">
        <button type="button" className="note-btn ql-clean" aria-label="스타일 제거" title="스타일 제거" />
      </span>
      <span className="ql-formats">
        <button type="button" className="note-btn ql-undo" aria-label="실행 취소" title="실행 취소">
          <CustomUndo />
        </button>
        <button type="button" className="note-btn ql-redo" aria-label="재실행" title="재실행">
          <CustomRedo />
        </button>
      </span>
    </div>
  )
}

export default QuillToolbar

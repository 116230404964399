import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useDispatch } from 'react-redux'
import { tableListConfig } from '@/util/table'
import API from '../../util/api'
import { setSnackSuccess } from '@/store/snackSlice'
import GroupDeptForm from '@/components/menu/GroupDeptForm'

const GroupDept = ({ open, groupId, closeModel }) => {
  const dispatch = useDispatch()
  const [ data, setData ] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [rowSelection, setRowSelection] = useState({})

  const selectList = useCallback(async () => {
    if (groupId !== '') {
      setLoading(true)
      const res = await API.get(`/api/groups/${groupId}/dept`).catch( () => {
        return {
          data: []
        }
      })
      setData(res.data)
      setLoading(false)
    } else {
      setData([])
      setRowSelection({})
    }
    setIsCreated(false)
  }, [groupId])

  useEffect(() => {
    selectList()
  }, [selectList])

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'deptId',
        header: '소속 코드',
        sortDescFirst: true
      },
      {
        accessorKey: 'deptName',
        header: '소속명'
      }
    ]
  }, [])

  const handleDelete = async () => {
    const form = {
      ids: Object.keys(rowSelection)
    }
    await API.post(`/api/groups/${groupId}/dept/deletes`, form).then( () => {
      dispatch(setSnackSuccess('삭제'))
    }).catch(() => {
    })
    selectList()
  }

  const creditTable = useMaterialReactTable({
    ...tableListConfig,
    columns,
    data,
    enableGlobalFilter: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableBottomToolbar: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: row.getToggleSelectedHandler(),
        sx: { cursor: 'pointer' }
      }
    },
    getRowId: (row) => { return row.id },
    renderTopToolbarCustomActions: () => {return (
      <Button
        primary
        onClick={() => {
          setIsCreated(true)
        }}
      >
        소속 추가
      </Button>
    )},
    state: {
      rowSelection,
      showProgressBars: isLoading
    }
  })

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
      open={open}
      size='small'
      style={{ background: 'none', boxShadow: 'unset' }}
      onClose={() => {return closeModel()}}
    >
      <Modal.Header>권한 소속 설정</Modal.Header>
      <Modal.Content>
        {
          isCreated === true ? <GroupDeptForm groupId={groupId} reloadFn={selectList} /> : null
        }
        <div className="tablet computer column">
          <div className="ui top attached message">
            <div className="header">등록된 소속 목록</div>
          </div>
          <div className="ui bottom attached segment">
            <MaterialReactTable table={creditTable} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleDelete}>삭제</Button>
        <Button color='grey' onClick={() => {
          setIsCreated(false)
          return closeModel()
        }}>닫기</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default GroupDept

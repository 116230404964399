import React from 'react'
import { useDropzone } from 'react-dropzone'
import API from '../../util/api'
import { deleteCfm } from '@/util/sweetalert'

const FileUploader = ({ refTable, fileList, setFileList }) => {

  const fileUpload = async (file) => {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('refTable', refTable)
    const fileInfo = await API.post('/api/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data
    })
    setFileList([...fileList, fileInfo])
  }

  const handleDrop = async (file) => {
    file.forEach( (el) => {
      fileUpload(el)
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true
  })

  const fileDelete = (fileId) => {
    deleteCfm('파일을 삭제하면 복구할 수 없습니다.').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/files/${fileId}`).then(() => {
        }).catch(() => {
        })
        setFileList([...fileList.filter(f => { return f.id !== fileId })])
      }
    })
  }

  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  return (
    <div className="upload">
      <div {...getRootProps({ className: 'drop' })}>
        <input {...getInputProps()} />
        <p>
          <button type="button" onClick={open}>
            파일 선택
          </button>
          또는 파일을 여기에 드레그 해 주세요.
        </p>
      </div>
      {fileList.length === 0 ? null :
        <ul className="multi_inbox"> {
          fileList.map((el) => {
            const size = bytesToSize(el.size)
            return (
              <li key={el.id} style={{ display: "flex" }}>
                <p>
                  <a href="#!" title={`Download : ${el.name}`} onClick={(e) => {
                    e.preventDefault()
                    document.location.href = `${process.env.REACT_APP_API_URL}/files/${el.id}`
                  }}>
                    {el.name}
                    <small>{size}</small>
                  </a>
                </p>
                <button type="button" onClick={ (e) => {
                  e.preventDefault()
                  fileDelete(el.id)
                }}>삭제</button>
              </li>
            )
          })
        }
        </ul>
      }
    </div>
  )
}

export default FileUploader

import React, { useCallback, useEffect, useState } from 'react'
import { Icon, Pagination } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import API from '@/util/api'
import NoContents from "../common/NoContents"

const Contents = ({ boardId, contentsId, pageSize, search, small, setTotalCount }) => {
  const navigate = useNavigate()
  const [ data, setData ] = useState([])
  const [ pageable, setPageable ] = useState({
    descending: true,
    page: 1,
    totalPage: 0,
    rowsNumber: 0,
    rowsPerPage: 10,
    searchKey: "",
    searchTxt: "",
    sortBy: ""
  })

  const selectList = useCallback(async (page, rowsPerPage, searchTxt) => {
    if (boardId !== '') {
      const res = await API.get(`/api/boards/${boardId}/contents`, {
        params: {
          page,
          rowsPerPage,
          searchTxt
        }
      }).catch( () => {
        return {
          data: []
        }
      })
      setData(res.data)
      if (res.pageable) {
        res.pageable.totalPage = Math.ceil(res.pageable.rowsNumber / res.pageable.rowsPerPage)
        if (setTotalCount) {
          setTotalCount(res.pageable.rowsNumber)
        }
      }
      setPageable(res.pageable)
    } else {
      setData([])
    }
  }, [boardId, setTotalCount])

  useEffect( () => {
    selectList(0, pageSize, search)
  }, [search, pageSize, selectList])

  return (
    <div>
      {(data.length > 0) ?
        <div>
          <div className="post_list">
            <ul className={ (small) ? 'thumb' : null }>
              {data.map((el) => {
                return (
                  <li key={el.id} className={`${(el.noticeYn === false) ? null : 'notice'} ${(el.id === contentsId) ? 'point' : null} `}>
                    {el.noticeYn === false ? null : (
                      <div className="post_label">
                        <strong>공지</strong>
                      </div>
                    )}
                    <a href="#!" onClick={(e) => {
                      e.preventDefault()
                      navigate(`/board/${boardId}/view/${el.id}`)
                    }}>
                      <span>{el.title}</span>
                      {(el.fileCnt === 0) ? null : <i className="paperclip icon" />}
                      { (moment().diff(moment(el.regDate, 'YYYY-MM-DD'), 'days') < 2) ? <i className="text-new">새글</i> : null }
                    </a>
                    {(small) ? null :
                      <ul className="post_disc">
                        <li>
                          {el.regName}({el.regId})
                        </li>
                        <li>{el.regDate}</li>
                      </ul>
                    }
                  </li>
                )
              })
              }
            </ul>
          </div>
          <div className="tc mb20">
            <Pagination
              boundaryRange={0}
              activePage={pageable.page}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              siblingRange={ (small) ? 2 : 4}
              totalPages={pageable.totalPage}
              onPageChange={(e, page) => {
                selectList((page.activePage - 1), pageSize, search)
              }}
            />
          </div>
        </div>
        :
        <NoContents/>
      }
    </div>
  )
}

export default Contents

export const defaultColor = [
  '#37A2EB',
  '#FF6384',
  '#FF9F40',
  '#FFCD56',
  '#4AC0C0',
  '#9966FF',
  '#C9CBCF'
]

export const ClassicCyclic13 = [
  '#1f83b4',
  '#12a2a8',
  '#2ca030',
  '#78a641',
  '#bcbd22',
  '#ffbf50',
  '#ffaa0e',
  '#ff7f0e',
  '#d63a3a',
  '#c7519c',
  '#ba43b4',
  '#8a60b0',
  '#6f63bb'
]

export const Classic20 = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5'
]

export const HueCircle19 = [
  '#1ba3c6',
  '#2cb5c0',
  '#30bcad',
  '#21B087',
  '#33a65c',
  '#57a337',
  '#a2b627',
  '#d5bb21',
  '#f8b620',
  '#f89217',
  '#f06719',
  '#e03426',
  '#f64971',
  '#fc719e',
  '#eb73b3',
  '#ce69be',
  '#a26dc2',
  '#7873c0',
  '#4f7cba'
]

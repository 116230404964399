import React, { useState } from 'react'
import { Switch } from '@mui/material'

const FormSwitch = ({ cell, row, column, table }) => {

  const [checked, setChecked] = useState((cell.getValue() === "") ? true : cell.getValue() )

  const onClick = (event) => {
    row._valuesCache[column.id] = event.target.checked
    setChecked(event.target.checked)
    if (table.options.state.creatingRow && table.options.state.creatingRow.id === row.id) {
      table.setCreatingRow(row)
    }
    if (table.options.state.editingRow && table.options.state.editingRow.id === row.id) {
      table.setEditingRow(row)
    }
  }

  return ( <Switch checked={checked} onChange={onClick} value /> )
}

export default FormSwitch

import React, { lazy, Suspense } from 'react'

const ButtonArea = ({ path }) => {
  const Component = (path) ? lazy(() => {
    return import(`../${path}`)
  }) : null

  return (
    <div className="button-area">
      <Suspense fallback={<div />}>
        {Component ? <Component /> : null}
      </Suspense>
    </div>
  )
}

export default ButtonArea

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import API from '@/util/api'
import { setMenuButton, setMenuTitle } from '@/store/menuSlice'
import { getBoardId, getBoardInfo, getSubmit, setBoardInfo, setSubmit, setEdit } from '@/store/boardSlice'
import Contents from '@/components/board/Contents'
import Detail from '@/components/board/Detail'

const View = () => {
  const storeBoardId = useSelector(getBoardId)
  const boardInfo = useSelector(getBoardInfo)
  const isSubmit = useSelector(getSubmit)
  const { boardId, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState({
    title: '',
    regDate: '',
    contents: '',
    editing: false,
    fileList: []
  })

  const handleEdit = useCallback( async () => {
    navigate(`/board/${boardId}/form?id=${data.id}`)
  }, [boardId, data.id, navigate])

  useEffect(() => {
    if (isSubmit === true) {
      handleEdit()
      dispatch(setSubmit(false))
    }
  }, [dispatch, handleEdit, isSubmit])

  const selectInfo = useCallback(async () => {
    const res = await API.get(`/api/boards/${boardId}/contents/${id}`).catch(() => {
      return {
        data: {},
      }
    })
    setData(res.data)

    await API.post(`/api/boards/${boardId}/contents/${id}/logs`, {}).catch(() => {})
    dispatch(setEdit(res.data.editing))
    dispatch(setMenuButton('board/ViewPageBtn'))

  }, [boardId, id, dispatch])

  const selectBoardInfo = useCallback(async () => {
    if (storeBoardId !== boardId) {
      const res = await API.get(`/api/boards/${boardId}`).catch( () => {
        return {
          data: {}
        }
      })
      dispatch(setBoardInfo(res.data))
      dispatch(setMenuTitle(`${res.data.name} 글보기`))
    } else {
      dispatch(setMenuTitle(`${boardInfo.name} 글보기`))
    }
  }, [boardId, boardInfo.name, dispatch, storeBoardId])

  useEffect(() => {
    selectBoardInfo()
    selectInfo()
  }, [selectBoardInfo, selectInfo])

  return (
    <div className="ui form">
      <div className="ui grid">
        <div className="six wide computer column mobile-hide">
          <Contents boardId={boardId} contentsId={data.id} pageSize={10} small />
        </div>
        <div className="sixteen wide tablet ten wide computer column">
          <Detail data={data} />
        </div>
      </div>
    </div>
  )
}

export default View

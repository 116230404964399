import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Pie } from 'react-chartjs-2'
import { isSearching, setSearching } from '@/store/statisticsSlice'
import API from '@/util/api'
import { defaultColor } from '@/util/chatColor'

const TopLogin = () => {
  const [data, setData] = useState({ labels: '', datasets: [] })
  const dispatch = useDispatch()
  const isSearch = useSelector(isSearching)
  const top = 5

  const onSearch = useCallback(async () => {
    const res = await API.get('/api/log/logins/top', {
      params: {
        top
      }
    }).catch(() => {
      return {
        data: []
      }
    })
    const datasets = {
      labels: res.data.map(m => { return m.str }),
      datasets: [
        {
          data: res.data.map(m => { return m.count }),
          backgroundColor: defaultColor
        },
      ]
    }
    setData(datasets)
    dispatch(setSearching(false))
  }, [dispatch])

  useEffect( () => {
    if (isSearch === true) {
      onSearch()
    }
  }, [isSearch, onSearch])


  return (
    <>
      <div className="ui attached message flex-item mt20">
        <div className="header">TOP {top} 로그인 사용자</div>
        <span className="h_subTitle flex-left-auto">전체 기간</span>
      </div>
      <div className="ui bottom attached segment">
        <div className="chart-box">
          <div className="chart-container">
            <div style={{ width: '100%', height: '200px' }}>
              <Pie data={data} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    color: '#383434',
                  },
                  legend: {
                    display: true,
                    position: 'right'
                  }
                }
              }} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopLogin

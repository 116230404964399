import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import API from '@/util/api'
import { setSnackSuccess } from '@/store/snackSlice'

const GroupDeptForm = ({ groupId, reloadFn }) => {
  const dispatch = useDispatch()
  const [ value, setValue ] = useState([])

  const selectList = async (inputValue) => {
    const res =  await API.get(`/api/groups/${groupId}/none`, {
      params: {
        search: inputValue
      }
    }).catch(() => {
    })
    return res.data
  }

  const debouncedFetch = (
    debounce((inputValue, callback) => {
      selectList(inputValue).then(callback)
    }, 300))

  const handleSave = async () => {
    await API.post(`/api/groups/${groupId}/dept`, {
      ids: value.map(option => { return option.deptId })
    }).then( () => {
      reloadFn()
      setValue([])
      dispatch(setSnackSuccess('저장'))
    }).catch(() => {
    })
  }

  return (
    <div className="tablet computer column mb45">
      <div className="ui top attached message">
        <div className="header">소속 추가</div>
      </div>
      <div className="ui bottom attached segment">
        <AsyncSelect
          isMulti
          cacheOptions
          value={value}
          loadOptions={debouncedFetch}
          defaultOptions
          getOptionLabel={(option) => {
            return `${option.deptName}(${option.deptId})`
          }}
          getOptionValue={(option) => {
            return `${option.deptId}`
          }}
          onChange={(e) => {
            setValue(e)
          }}
        />
        <div className="mt5 tr">
          <Button primary onClick={handleSave}>등록</Button>
        </div>
      </div>
    </div>
  )
}

export default GroupDeptForm

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FormSwitch from '@/components/table/FormSwitch'
import { tableListConfig } from '@/util/table'
import API from '../../util/api'
import { setSnackSuccess } from '@/store/snackSlice'
import { deleteCfm } from '@/util/sweetalert'
import { Validate } from '@/util/validate'
import { setMenuButton } from '@/store/menuSlice'
import { getSubmit, setSubmit } from '@/store/boardSlice'

const Info = () => {
  const dispatch = useDispatch()
  const [ data, setData ] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [isLoading, setLoading] = useState(false)
  const isSubmit = useSelector(getSubmit)

  const selectList = useCallback(async () => {
    setLoading(true)
    const res = await API.get('/api/boards').catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
    setLoading(false)
  }, [])

  useEffect(() => {
    selectList()
  }, [selectList])

  const validateCheck = (menu) => {
    return {
      name: !Validate.Required(menu.name) ? '게시판명을 입력하세요.' : ''
    }
  }

  const handleCreate = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.post('/api/boards', values).then(() => {
      setValidationErrors({})
      table.setCreatingRow(null)
      dispatch(setSnackSuccess('등록'))
      selectList()
    }).catch(() => {
    })
  }

  const handleEdit = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.put(`/api/boards/${values.id}`, values).then(() => {
      setValidationErrors({})
      table.setEditingRow(null)
      dispatch(setSnackSuccess('수정'))
      selectList()
    }).catch(() => {
    })
  }

  const handleDelete = (row) => {
    deleteCfm('').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/boards/${row.original.id}`).then(() => {
          setValidationErrors({})
          dispatch(setSnackSuccess('삭제'))
          selectList()
        }).catch(() => {
        })
      }
    })
  }

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'id',
        header: '아이디',
        enableEditing: false
      },
      {
        accessorKey: 'name',
        header: '게시판명',
        sortDescFirst: true,
        muiEditTextFieldProps: {
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
        }
      },
      {
        accessorKey: 'contentsCnt',
        header: '게시글 수',
        enableEditing: false,
      },
      {
        accessorKey: 'noticeYn',
        header: '공지',
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.noticeYn === '') ? true : row.noticeYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      },
      {
        accessorKey: 'useYn',
        header: '사용여부',
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.useYn === '') ? true : row.useYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      },
    ]
  }, [validationErrors])

  const creditTable = useMaterialReactTable({
    ...tableListConfig,
    columns,
    data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    enableMultiRowSelection: false,
    enableBottomToolbar: false,
    onCreatingRowCancel: () => {return setValidationErrors({})},
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => {return setValidationErrors({})},
    onEditingRowSave: handleEdit,
    getRowId: (row) => { return row.id },
    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="수정">
            <IconButton onClick={() => {
              return table.setEditingRow(row)
            }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="삭제">
            <IconButton color="error" onClick={() => { return handleDelete(row) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    enableGlobalFilter: false,
    enablePagination: false,
    manualSorting: false,
    positionToolbarAlertBanner: 'none',
    state: {
      isLoading,
      showProgressBars: isLoading
    }
  })

  useEffect(() => {
    dispatch(setMenuButton('board/CreateInfoBtn'))
  }, [dispatch])

  useEffect(() => {
    if (isSubmit === true) {
      creditTable.setCreatingRow(true)
      dispatch(setSubmit(false))
    }
  }, [dispatch, creditTable, isSubmit])

  return (
    <div>
      <MaterialReactTable table={creditTable}/>
    </div>
  )
}

export default Info

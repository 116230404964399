import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '@/util/api'

export const authLogout = createAsyncThunk(
  'auth/logout',
  async () => {
    await API.post('/api/logout').catch(() => {})
    return {}
  }
)

const initialState = {
  isLogin: false,
  status: 'NONE',
  user: {
    userId: '',
    userName: '',
    deptId: '',
    deptName: '',
    depts: [],
    role: []
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.state = 'LOGIN'
      state.isLogin = true
      state.user.userId = action.payload.userId
      state.user.userName = action.payload.userName
      state.user.deptId = action.payload.deptId
      state.user.deptName = action.payload.deptName
      state.user.depts = action.payload.depts
      state.user.role = action.payload.role
    },
    setDept: (state, action) => {
      state.user.deptId = action.payload.deptId
      state.user.deptName = action.payload.deptName
    },
    authClear: (state) => {
      state.state = 'NONE'
      state.isLogin = false
      state.user = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authLogout.fulfilled, (state) => {
      state.state = 'LOGOUT'
      state.isLogin = false
      state.user = {
        userId: '',
        userName: '',
        deptId: '',
        deptName: '',
        depts: [],
        role: []
      }
    })
  },
})

export const { setAuth, setDept, authClear } = authSlice.actions

export const getUserInfo = (state) => { return state.auth.user }
export const getIsLogin = (state) => { return state.auth.isLogin }
export const getAuthStatus = (state) => { return state.auth.state }
export const getDeptId = (state) => { return state.auth.user.deptId }
export const getRole = (state) => { return state.auth.user.role }

export default authSlice.reducer

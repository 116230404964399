import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuId, setMenuInfo } from '@/store/menuSlice'

const SubMenu = ({ subRows }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const menuId = useSelector(getMenuId)

  return (
    <ul className="gn-submenu">
      { (!subRows) ? null : subRows.map((info) => {
        return (
          <li key={info.id}>
            {!info.url ? (
              <p>{info.title}</p>
            ) : (
              <a href='#!' className={ ( menuId === info.id) ? 'on' : '' } onClick={(e) => {
                e.preventDefault()
                dispatch(setMenuInfo(info))
                navigate(info.url)
              }}>
                {info.title}
              </a>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default SubMenu

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMenuTitle, getMenuTitleBtn, menuToggleStatus } from '@/store/menuSlice'
import ButtonArea from '@/components/common/ButtonArea'

const ContainerTitle = () => {
  const title = useSelector(getMenuTitle)
  const buttonPath = useSelector(getMenuTitleBtn)
  const [sticky, setSticky] = useState(false)
  const [marginTop, setMarginTop] = useState('0px')
  const [leftStyle, setLeftStyle] = useState('0px')
  const [widthStyle, setWidthStyle] = useState('')
  const toggleStatus = useSelector(menuToggleStatus)

  const handleShowButton = () => {
    const scrollTop = window.scrollY
    setSticky((scrollTop >= 10))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleShowButton)
  }, [])

  useEffect(() => {
    const context = document.querySelector(".content")
    setWidthStyle()
    if (sticky === true) {
      if (context.offsetWidth <= 1024) {
        setMarginTop('0px')
        setLeftStyle('0px')
      } else {
        setMarginTop('60px')
        setLeftStyle(toggleStatus ? '280px' : '60px')
      }
      setWidthStyle( `${context.offsetWidth}px`)
    } else {
      setMarginTop('0px')
    }
  }, [sticky, toggleStatus])

  return (
    <div id="info-item-box" className={`ui sticky ${(sticky === true) ? 'fixed top' : '' }`} style={{
      marginTop: `${ marginTop }`,
      left: `${ leftStyle }`,
      width: `${ widthStyle }`
    }}>
      <h2 className="page-title">{title}</h2>
      <ButtonArea path={buttonPath} />
    </div>
  )
}

export default ContainerTitle

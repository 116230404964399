// eslint-disable-next-line camelcase
import { MRT_Localization_KO } from 'material-react-table/locales/ko'

// eslint-disable-next-line camelcase
MRT_Localization_KO.actions = '관리'

const tableStyle = {
  muiSearchTextFieldProps: {
    sx: { minWidth: '300px' },
    variant: 'outlined',
  },
  muiTopToolbarProps: {
    sx: { zIndex: 'initial' },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      borderRadius: '0',
    },
  },
  muiTableBodyRowProps: ({ row, table }) => {
    const { density } = table.getState()
    let height = 69
    if (density === 'compact') {
      height = 37
    } else if (density === 'comfortable') {
      height = 53
    }
    return {
      sx: {
        height: row.getIsPinned() ? `${height}px` : undefined,
      },
    }
  },
}

export const tablePageConfig = {
  ...tableStyle,
  enableSortingRemoval: false,
  manualSorting: true,
  enableColumnFilters: false,
  positionActionsColumn: 'last',
  initialState: {
    showGlobalFilter: true,
  },
  positionGlobalFilter: 'left',
  manualFiltering: true,
  manualPagination: true,
  muiPaginationProps: {
    rowsPerPageOptions: [ 10, 20, 50, 100],
    color: 'primary',
    shape: 'rounded',
    showRowsPerPage: true,
    variant: 'outlined',
  },
  paginationDisplayMode: 'pages',
  // eslint-disable-next-line camelcase
  localization: MRT_Localization_KO
}

export const tableListConfig = {
  ...tableStyle,
  enableGlobalFilter: true,
  enablePagination: false,
  manualSorting: false,
  positionActionsColumn: 'last',
  enableSortingRemoval: false,
  enableColumnFilters: false,
  positionGlobalFilter: 'left',
  muiPaginationProps: {
    rowsPerPageOptions: [ 10, 20, 50, 100],
    color: 'primary',
    shape: 'rounded',
    showRowsPerPage: true,
    variant: 'outlined',
  },
  paginationDisplayMode: 'pages',
  // eslint-disable-next-line camelcase
  localization: MRT_Localization_KO
}

const tableConfig = {
  tablePageConfig,
  tableListConfig
}

export default tableConfig

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteCfm } from '@/util/sweetalert'
import API from '@/util/api'
import { setSnackSuccess } from '@/store/snackSlice'
import { getRole } from '@/store/authSlice'
import LogList from '@/components/board/LogList'

const Detail = ({ data }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const role = useSelector(getRole)
  const [open, setOpen] = React.useState(false)

  const handleDelete = useCallback(() => {
    deleteCfm('').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/boards/${data.boardId}/contents/${data.id}`).then(() => {
          dispatch(setSnackSuccess('삭제'))
          navigate(`/board/${data.boardId}/list`, { replace: true })
        }).catch(() => {
        })
      }
    })
  }, [data.boardId, data.id, dispatch, navigate])

  return (
    <div className="post_view">
      <div className="header">
        <div className="inner-box">
          {data.noticeYn === false ? null : (
            <h4>
              <div className="ui basic small red label">공지</div>
            </h4>
          )}
          {data.title}
        </div>
        <ul className="viewInfo">
          <li>
            <span>조회 수</span> {data.viewCnt}
            {
              (role.includes('ADMIN')) ? <button type="button" className="ml5" onClick={ () => { setOpen(true) }}><i className="ion-search" />상세보기</button>  : null
            }
          </li>
          <li>
            <span>글쓴이</span> {data.regName}({data.regId})
          </li>
          <li>
            <span>작성일시</span> {data.regDate}
          </li>
          {(!data.editing) ? null :
            <li>
              <button type="button" onClick={handleDelete}><i className="ion-close" />삭제</button>
            </li>
          }
        </ul>
        {(data.fileList.length === 0) ? null :
          <ul className="viewInfo file">
            {
              data.fileList.map((el) => {
                return (
                  <li key={el.id}>
                    <a
                      href="#!"
                      title={`Download : ${el.name}`}
                      onClick={(e) => {
                        e.preventDefault()
                        document.location.href = `${process.env.REACT_APP_API_URL}/files/${el.id}`
                      }}
                    >
                      <i className="paperclip icon" />{el.name}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        }
      </div>
      <div className="content-field">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: data.contents }} />
      </div>
      {
        (role.includes('ADMIN')) ? <LogList boardId={data.boardId} contentsId={data.id} open={open} closeModel={ () => {
          setOpen(false)
        }} />  : null
      }
      <div className="ui divider" />
    </div>
  )
}

export default Detail

import React, { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../util/api'
import { Validate } from '@/util/validate'
import { setSnackSuccess } from '@/store/snackSlice'
import { confirmFn, deleteCfm } from '@/util/sweetalert'
import FormSwitch from '@/components/table/FormSwitch'
import { tablePageConfig } from '@/util/table'
import { setMenuButton } from '@/store/menuSlice'
import { getSubmit, setSubmit } from '@/store/boardSlice'

const Setting = () => {
  const dispatch = useDispatch()
  const [ data, setData ] = useState([])
  const [ rowCount, setRowCount ] = useState(0)
  const [sorting, setSorting] = useState([
    {
      id: 'id',
      desc: false
    }
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [globalFilter, setGlobalFilter] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const isSubmit = useSelector(getSubmit)

  const selectList = async (page, rowsPerPage, sortBy, descending, searchTxt) => {
    setLoading(true)
    const res = await API.get('/api/syncs', {
      params: {
        page,
        rowsPerPage,
        sortBy,
        descending,
        searchTxt
      }
    }).catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
    if (res.pageable) {
      setRowCount(res.pageable.rowsNumber)
    }
    setLoading(false)
  }

  useEffect(() => {
    selectList(pagination.pageIndex, pagination.pageSize, sorting[0].id, sorting[0].desc, globalFilter)
  }, [sorting, globalFilter, pagination.pageIndex, pagination.pageSize])

  const validateCheck = (menu) => {
    return {
      name: !Validate.Required(menu.name) ? '그룹명을 입력하세요.' : ''
    }
  }

  const handleCreate = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.post('/api/syncs', values).then(() => {
      setValidationErrors({})
      table.setCreatingRow(null)
      selectList()
      dispatch(setSnackSuccess('등록'))
    }).catch(() => {
    })
  }

  const handleEdit = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.put(`/api/syncs/${values.id}`, values).then(() => {
      setValidationErrors({})
      table.setEditingRow(null)
      selectList()
      dispatch(setSnackSuccess('수정'))
    }).catch(() => {
    })
  }

  const handleDelete = (row) => {
    deleteCfm('').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/syncs/${row.original.id}`).then(() => {
          setValidationErrors({})
          dispatch(setSnackSuccess('삭제'))
          selectList()
        }).catch(() => {
        })
      }
    })
  }

  const handleSync = (row) => {
    confirmFn(`${row.original.description} 연동을 진행하시겠습니까?`, "연동 결과는 로그 조회로 확인이 가능합니다.").then(async (result) => {
      if (result.isConfirmed) {
        await API.put(`/api/syncs/${row.original.id}/sync`).then(() => {
          dispatch(setSnackSuccess('연동'))
        }).catch(() => {
        })
      }
    })
  }

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'id',
        header: '스케줄러 아이디',
        enableEditing: false,
        sortDescFirst: true
      },
      {
        accessorKey: 'name',
        header: '모듈명',
        muiEditTextFieldProps: {
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
        }
      },
      {
        accessorKey: 'description',
        header: '설명',
      },
      {
        accessorKey: 'cron',
        header: '작업 주기',
      },
      {
        accessorKey: 'url',
        header: 'URL',
      },
      {
        accessorKey: 'searchParam',
        header: '검색 조건',
      },
      {
        accessorKey: 'useYn',
        header: '사용여부',
        size: 30,
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.useYn === '') ? true : row.useYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      },
      {
        accessorKey: 'nextDate',
        header: '다음 실행 일시',
        enableEditing: false,
      },
    ]
  }, [validationErrors])

  const creditTable = useMaterialReactTable({
    ...tablePageConfig,
    columns,
    data,
    rowCount,
    enableEditing: true,
    onCreatingRowCancel: () => {return setValidationErrors({})},
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => {return setValidationErrors({})},
    onEditingRowSave: handleEdit,
    getRowId: (row) => { return row.id },
    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="실시간 연동">
            <IconButton onClick={() => {
              return handleSync(row)
            }}>
              <SyncIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="수정">
            <IconButton onClick={() => {
              return table.setEditingRow(row)
            }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="삭제">
            <IconButton color="error" onClick={() => { return handleDelete(row) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    positionToolbarAlertBanner: 'none',
    state: {
      sorting,
      isLoading,
      showProgressBars: isLoading,
      globalFilter,
      pagination
    }
  })

  useEffect(() => {
    dispatch(setMenuButton('sync/SettingBtn'))
  }, [dispatch])

  useEffect(() => {
    if (isSubmit === true) {
      creditTable.setCreatingRow(true)
      dispatch(setSubmit(false))
    }
  }, [dispatch, creditTable, isSubmit])

  return (
    <div>
      <MaterialReactTable table={creditTable}/>
    </div>
  )
}

export default Setting

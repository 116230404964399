import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table'
import API from '@/util/api'
import { tablePageConfig } from '@/util/table'
import EditUserDept from '@/components/user/EditUserDept'
import { setSnackSuccess } from '@/store/snackSlice'

const Index = () => {
  const [ data, setData ] = useState([])
  const dispatch = useDispatch()
  const [ rowCount, setRowCount ] = useState(0)
  const [sorting, setSorting] = useState([
    {
      id: 'id',
      desc: true
    }
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [globalFilter, setGlobalFilter] = useState('')
  const [isLoading, setLoading] = useState(false)

  const selectList = async (page, rowsPerPage, sortBy, descending, searchTxt) => {
    setLoading(true)
    const res = await API.get('/api/users', {
      params: {
        page,
        rowsPerPage,
        sortBy,
        descending,
        searchTxt
      }
    }).catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
    if (res.pageable) {
      setRowCount(res.pageable.rowsNumber)
    }
    setLoading(false)
  }

  useEffect(() => {
    selectList(pagination.pageIndex, pagination.pageSize, sorting[0].id, sorting[0].desc, globalFilter)
  }, [sorting, globalFilter, pagination.pageIndex, pagination.pageSize])

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'id',
        header: '교번',
        enableEditing: false,
        sortDescFirst: true
      },
      {
        accessorKey: 'name',
        header: '이름',
        enableEditing: false
      },
      {
        accessorFn: (row) => { return `${row.deptName}(${row.deptId})` },
        accessorKey: 'dept.name',
        header: '소속',
        Edit: (props) => {
          return EditUserDept(props)
        },
      }
    ]
  }, [])

  const handleEdit = async ({ row, values, table }) => {
    const deptName = values['dept.name']
    const user = {
      keyId: row.id,
      id: values.id,
      name: values.name,
      deptId: deptName.substring(deptName.indexOf('(') + 1, deptName.indexOf(')'))
    }
    await API.put(`/api/users/${values.id}`, user).then(() => {
      table.setEditingRow(null)
      dispatch(setSnackSuccess('수정'))
    }).catch(() => {
    })
  }

  const creditTable = useMaterialReactTable({
    ...tablePageConfig,
    columns,
    data,
    rowCount,
    enableEditing: true,
    editDisplayMode: 'row',
    onEditingRowSave: handleEdit,
    getRowId: (row) => { return row.keyId },
    state: {
      sorting,
      isLoading,
      showProgressBars: isLoading,
      globalFilter,
      pagination
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting
  })

  return (
    <div>
      <MaterialReactTable table={creditTable} />
    </div>
  )
}

export default Index

import React from 'react'

const MainIndex = () => {

  return (
    <div>
      테스트
    </div>
  )
}

export default MainIndex

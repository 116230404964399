import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Checkbox, FormField, Input } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import 'moment/locale/ko'
import API from '@/util/api'
import Editor from '@/components/editor/Editor'
import { setSnackSuccess } from '@/store/snackSlice'
import { getBoardId, getBoardInfo, getSubmit, setBoardInfo, setSubmit } from '@/store/boardSlice'
import SweetAlert from '@/util/sweetalert'
import Contents from '@/components/board/Contents'
import FileUploader from '@/components/common/FileUploader'
import { setMenuButton, setMenuTitle } from '@/store/menuSlice'

const Form = () => {
  const storeBoardId = useSelector(getBoardId)
  const boardInfo = useSelector(getBoardInfo)
  const isSubmit = useSelector(getSubmit)
  const { boardId } = useParams()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [noticeYn, setNoticeYn] = useState(false)
  const [fileList, setFileList] = useState([])
  const [data, setData] = useState({
    title: '',
    startDate: '',
    endDate: '',
    contents: '',
  })

  const goList = useCallback(() => {
    navigate(`/board/${boardId}/list`, { replace: true })
  }, [boardId, navigate])

  const handleCreate = useCallback( async () => {
    const form = { ...data }
    if (fileList.length > 0) {
      form.fileList = fileList
    }
    if (noticeYn === false) {
      form.startDate = ''
      form.endDate = ''
    }
    if (noticeYn === true && (form.startDate === '' || form.endDate === '')) {
      SweetAlert.warning('공지 기간을 입력하세요.')
      return
    }
    if (form.title === '') {
      SweetAlert.warning('제목을 입력하세요.')
      return
    }
    if (form.contents === '') {
      SweetAlert.warning('내용을 입력하세요.')
      return
    }
    if (searchParams.get('id')) {
      await API.put(`/api/boards/${boardId}/contents/${searchParams.get('id')}`, form).then(() => {
        dispatch(setSnackSuccess('수정'))
        goList()
      }).catch(() => {
      })
    } else {
      await API.post(`/api/boards/${boardId}/contents`, form).then(() => {
        dispatch(setSnackSuccess('등록'))
        goList()
      }).catch(() => {
      })
    }
  }, [data, fileList, noticeYn, searchParams, boardId, dispatch, goList])

  const selectInfo = useCallback(async () => {
    if (storeBoardId !== boardId) {
      const res = await API.get(`/api/boards/${boardId}`).catch( () => {
        return {
          data: {}
        }
      })
      dispatch(setBoardInfo(res.data))
    }
  }, [boardId, dispatch, storeBoardId])

  const selectContents = useCallback( async () => {
    if (searchParams.get('id')) {
      const res = await API.get(`/api/boards/${boardId}/contents/${searchParams.get('id')}`).catch(() => {
        return {
          data: {},
        }
      })
      if (res.data === '' || res.data.editing === false) {
        goList()
      }
      setData(res.data)
      setNoticeYn((res.data.startDate !== '' && res.data.endDate !== ''))
      if (res.data.fileList > 0) {
        setFileList(res.data.fileList)
      }
    }
  }, [boardId, goList, searchParams])

  useEffect(() => {
    selectContents()
  }, [selectContents])

  useEffect(() => {
    dispatch(setMenuTitle(`${boardInfo.name} 글쓰기`))
    dispatch(setMenuButton('board/FormPageBtn'))
  }, [boardId, boardInfo.name, dispatch])

  useEffect(() => {
    selectInfo()
  }, [selectInfo])

  useEffect(() => {
    if (isSubmit === true) {
      handleCreate()
      dispatch(setSubmit(false))
    }
  }, [dispatch, handleCreate, isSubmit])

  const setContents = (value) => {
    setData({ ...data, contents: value })
  }

  const setNoticeDate = (event, {  name, value }) => {
    if (name === "startDate") {
      setData({ ...data, startDate: value })
    } else {
      setData({ ...data, endDate: value })
    }
  }

  return (
    <div className="ui form">
      <div className="ui grid">
        <div className="six wide computer column mobile-hide">
          <Contents boardId={boardId} contentsId={data.id} pageSize={10} small />
        </div>
        <div className="sixteen wide tablet ten wide computer column">
          <ul className="tbl-simple">
            {(boardInfo.noticeYn === false) ? null :
              <li>
                <div className="inline fields mo-flex1-row mb0">
                  <FormField>
                    <Checkbox label="공지글 지정" onChange={(e, value) => {
                      setNoticeYn(value.checked)
                    }} checked={noticeYn} />
                  </FormField>
                  { (noticeYn === false) ? null :
                    <div className="inline fields mb0">
                      <DateInput
                        name="startDate"
                        dateFormat="YYYY-MM-DD"
                        placeholder="시작일"
                        value={data.startDate}
                        maxDate={data.endDate}
                        iconPosition="left"
                        localization="ko"
                        onChange={setNoticeDate}
                      />
                      <DateInput
                        name="endDate"
                        dateFormat="YYYY-MM-DD"
                        placeholder="종료일"
                        minDate={data.startDate}
                        value={data.endDate}
                        iconPosition="left"
                        localization="ko"
                        onChange={setNoticeDate}
                      />
                    </div>
                  }
                </div>
              </li>
            }
            <li>
              <div className="field">
                <div className="ui fluid input">
                  <Input placeholder='제목을 입력하세요' defaultValue={data.title} onChange={(e) => {
                    setData({ ...data, title: e.target.value })
                  }} />
                </div>
              </div>
            </li>
            <li>
              <Editor contents={data.contents} setValue={setContents}/>
            </li>
            <li>
              <FileUploader refTable="board" refId={data.id} fileList={fileList} setFileList={setFileList} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Form

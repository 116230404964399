import React, { forwardRef } from 'react'
import { Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import MuiAlert from '@mui/material/Alert'
import { getSnackState, getSnackTitle, isSnackOpen, setSnackClear } from '@/store/snackSlice'

const Alert = forwardRef((
  props,
  ref,
) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SnackAlert = () => {
  const open = useSelector(isSnackOpen)
  const message = useSelector(getSnackTitle)
  const state = useSelector(getSnackState)
  const dispatch = useDispatch()

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackClear())
  }

  return (
    <Snackbar open={ open } autoHideDuration={1500} onClose={handleClose}>
      <Alert onClose={handleClose} severity={state} sx={{ width: '100%' }}>
        { message }
      </Alert>
    </Snackbar>
  )
}

export default SnackAlert

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { useSelector } from 'react-redux'
import { getParam } from '@/store/menuSlice'
import API from '@/util/api'

const Index = () => {
  const param = useSelector(getParam)
  const dashboardRef = useRef([])
  const [embeddingContext, setEmbeddingContext] = useState(null)
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null)

  const createContext = async () => {
    const context = await createEmbeddingContext()
    setEmbeddingContext(context)
  }

  useEffect(() => {
    createContext()
  }, [])

  const getUrl = useCallback(async () => {
    const res = await API.get(`/api/quicksight/dashboards/${param}`).catch( () => {
      return {
        data: []
      }
    })
    const frameOptions = {
      url: res,
      container: dashboardRef.current,
      height: '780px',
      width: '100%',
      scrolling: "auto",
      withIframePlaceholder: true,
      resizeHeightOnSizeChangedEvent: true
    }
    const contentOptions = {
      locale: 'ko-KR',
      toolbarOptions: {
        export: false,
        undoRedo: false,
        reset: false
      },
      onMessage: async (messageEvent) => {
        switch (messageEvent.eventName) {
        case 'MODAL_OPENED': {
          window.scrollTo({
            top: 0
          })
          break
        }
        default:
          break
        }
      }
    }
    if (embeddingContext) {
      const newEmbeddedDashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions)
      setEmbeddedDashboard(newEmbeddedDashboard)
    }
  }, [embeddingContext, param])

  useEffect(
    () => {
      if (embeddedDashboard) {
        const iframe = document.getElementById(embeddedDashboard.experienceId)
        iframe.height = '780px'
        embeddedDashboard.navigateToDashboard(param, {})
      } else if (param) {
        getUrl()
      }
    }, [embeddedDashboard, getUrl, param])

  return (
    <div>
      <div ref={dashboardRef} />
    </div>
  )
}

export default Index

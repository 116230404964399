import axios from 'axios'
import moment from 'moment/moment'
import SweetAlert from './sweetalert'

const API = axios.create({
  headers: {
    "Content-Type": "application/json",
    withCredentials: true
  }
})

API.interceptors.response.use(
  (response) => {
    return response.data
  },
  ({ response }) => {
    let resData = response.data
    if (response.status === 401) {
      SweetAlert.logout('로그아웃되었습니다.')
      resData = {
        status: 401,
        message: '로그아웃되었습니다.',
        errors: [],
        timestamp: moment().format('MMMM Do YYYY, h:mm:ss')
      }
    } else if (response.status === 504) {
      SweetAlert.error('서버에 응답이 없습니다.', '잠시 후 다시 이용하시기 바랍니다.')
      resData = {
        status: 504,
        message: '서버에 응답이 없습니다.',
        errors: [],
        timestamp: moment().format('MMMM Do YYYY, h:mm:ss')
      }
    } else {
      SweetAlert.error(resData.message)
    }
    return Promise.reject(resData)
  }
)

export default API

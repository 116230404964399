import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import MenuList from './MenuList'
import Avatar from '../common/Avatar'
import { getUserInfo, authLogout, setDept } from '@/store/authSlice'
import { getFirstMenuInfo, menuToggleStatus, setMenuInfo, setMenuToggle, setOpenId } from '@/store/menuSlice'
import API from '@/util/api'

const Header = () => {
  const user = useSelector(getUserInfo)
  const dispatch = useDispatch()
  const toggleStatus = useSelector(menuToggleStatus)
  const firstMenuInfo = useSelector(getFirstMenuInfo)
  const navigate = useNavigate()
  const [overlay, setOverlay]  = useState(false)
  const [deptOptions, setDeptOptions]  = useState([])
  let nowZoom = 100

  const zoomOut = () => {
    nowZoom -= 10
    if (nowZoom <= 80) nowZoom = 80
    document.body.style.zoom = `${nowZoom  }%`
  }

  const zoomIn = () => {
    nowZoom += 10
    if (nowZoom >= 120) nowZoom = 120
    document.body.style.zoom = `${nowZoom  }%`
  }

  const zoomReset = () => {
    nowZoom = 100
    document.body.style.zoom = `${nowZoom  }%`
  }

  const goFirst = (e) => {
    e.preventDefault()
    dispatch(setMenuInfo({ ...firstMenuInfo, first: false }))
    dispatch(setOpenId(firstMenuInfo.parentId))
    navigate(firstMenuInfo.url)
  }

  const logout = async (e) => {
    e.preventDefault()
    dispatch(authLogout())
  }

  const hideMenu = () => {
    dispatch(setMenuToggle())
    setOverlay(false)
  }

  useEffect(() => {
    if (toggleStatus) {
      setOverlay(true)
    }
  }, [toggleStatus])

  const changeDept = async (e, select) => {
    const found = select.options.find(f => {return f.value === select.value})
    const dept = found ? { id: found.value, name: found.text } : null
    if (dept) {
      const status = await API.post('/api/auth/change', dept).then(() => {
        dispatch(setDept({ deptId: dept.id, deptName: dept.name }))
        return true
      }).catch(() => {
        return false
      })

      if (status === true) {
        await API.get('/api/menus/first').then(({ data }) => {
          dispatch(setMenuInfo({ ...data, first: true }))
          dispatch(setOpenId(firstMenuInfo.parentId))
          navigate(firstMenuInfo.url)
        }).catch(() => {
        })
      }
    }
  }

  useEffect(() => {
    const depts = user.depts.map((dept) => {return  { key: dept.id, value: dept.id, text: dept.name }})
    setDeptOptions(depts)
  }, [user.depts])

  return (
    <header id="gn-menu" className="gn-menu-main admin_menu">
      <MenuList />
      <div className="overlay" style={ { display: overlay ? 'block' : 'none' } } onClick={hideMenu} role="presentation" />
      <h1>
        <a href="#!" onClick={goFirst}>
          <img className="main_logo" src="/images/logo.png" alt="서울여자대학교 로고" />
        </a>
      </h1>
      <ul className="util">
        {
          (user.depts.length === 1) ? '' :
            <li>
              <span className="mr5">소속을</span>
              <Dropdown placeholder='State' defaultValue={user.deptId} selection options={deptOptions} onChange={changeDept} />
              <span className="ml5">전환</span>
            </li>
        }
        <li className="zoom-control">
          <div className="ui icon basic buttons">
            <button type="button" className="ui button" onClick={zoomIn} aria-label="화면 확대">
              <i className="zoom-in icon" />
            </button>
            <button type="button" className="ui button" onClick={zoomOut} aria-label="화면 축소">
              <i className="zoom-out icon" />
            </button>
            <button
              type="button"
              className="ui button"
              onClick={zoomReset}
              aria-label="화면 원복"
            >
              <i className="sync icon" />
            </button>
          </div>
        </li>
        <li>
          <Dropdown trigger={Avatar({ name: user.userName })} icon={null} className="user-img">
            <Dropdown.Menu>
              <div className="item profile">
                <Avatar name={user.userName} />
                <ul>
                  <li>{`${user.userName}(${user.userId})`}</li>
                  <li>{user.deptName}</li>
                </ul>
              </div>
              <div className="divider m0" />
              <a href="#!" className="item" onClick={logout}>
                <i className="sign-out icon" />로그아웃
              </a>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </header>
  )
}

export default Header

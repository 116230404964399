import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  state: 'success',
  title: ''
}

export const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    setSnackSuccess: (state, action) => {
      state.title = `정상적으로 ${action.payload} 되었습니다.`
      state.state = 'success'
      state.open = true
    },
    setSnackWarning: (state, action) => {
      state.state = 'warning'
      state.title = action.payload
      state.open = true
    },
    setSnackError: (state, action) => {
      state.state = 'error'
      state.title = action.payload
      state.open = true
    },
    setSnackClear: (state) => {
      state.open = false
    }
  }
})

export const { setSnackSuccess, setSnackWarning, setSnackClear } = snackSlice.actions

export const isSnackOpen = (state) => { return state.snack.open }
export const getSnackState = (state) => { return state.snack.state }
export const getSnackTitle = (state) => { return state.snack.title }

export default snackSlice.reducer

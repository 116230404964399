import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setMenuInfo, setOpenId } from '@/store/menuSlice'

const TopMenu = ({ info }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return info.url ? (
    <a href="#!" onClick={ (e) => {
      e.preventDefault()
      dispatch(setMenuInfo(info))
      navigate(info.url)
    }} className={`gn-icon ${info.icon}`}>
      <strong>{info.title}</strong>
    </a>
  ) : (
    <a href="#!" onClick={ (e) => {
      e.preventDefault()
      dispatch(setOpenId(info.id))
    }} className={`gn-icon ${info.icon}`}>
      <strong>{info.title}</strong>
    </a>
  )
}

export default TopMenu

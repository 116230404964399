import React from 'react'
import ReactQuill from "react-quill"
import EditorToolbar, { modules, formats } from "./EditorToolbar"
import "react-quill/dist/quill.snow.css"
import 'quill-image-uploader/dist/quill.imageUploader.min.css'

const Editor = ({ contents, setValue }) => {
  const handleChange = value => {
    setValue(value)
  }

  return (
    <div className="text-editor">
      <EditorToolbar/>
      <ReactQuill
        theme="snow"
        value={contents}
        onChange={handleChange}
        placeholder="네용을 입력하세요."
        modules={modules}
        formats={formats}
      />
    </div>
  )
}

export default Editor

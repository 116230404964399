import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import API from '@/util/api'

const EditUserDept = ({ cell, row, column, table }) => {
  const [defValue, setDefValue] = useState('')
  const [data, setData] = useState([])
  const handleChange = (event, newValue) => {
    row._valuesCache[column.id] = `${newValue.name}(${newValue.id})`
    table.setEditingRow(row)
    setDefValue(`${newValue.name}(${newValue.id})`)
  }

  const selectList = async () => {
    const res = await API.get('/api/depts', {
      params: {
        page: 0,
        rowsPerPage: 10000,
      }
    }).catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
  }

  useEffect(() => {
    selectList()
  }, [])

  useEffect(() => {
    setDefValue(cell.getValue())
  }, [cell])

  return (
    <FormControl sx={{ width: '90%' }}>
      <Autocomplete
        value={defValue}
        options={data}
        isOptionEqualToValue={(option, value) => {
          return `${option.name}(${option.id})` === value
        }}
        getOptionKey={(option) => { return `${option.name} (${option.id})` || '' }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return  `${option.name}(${option.id})` || ''
        }}
        disableClearable
        blurOnSelect
        renderInput={(params) => {
          return <TextField {...params} variant="standard" />
        }}
        onChange={handleChange}
      />
    </FormControl>
  )
}

export default EditUserDept

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { tablePageConfig } from '@/util/table'
import API from '@/util/api'

const LogList = ({ open, boardId, contentsId, closeModel }) => {
  const [ data, setData ] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [ rowCount, setRowCount ] = useState(0)
  const [sorting, setSorting] = useState([
    {
      id: 'regDate',
      desc: true
    }
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [globalFilter, setGlobalFilter] = useState('')

  const selectList = useCallback(async (page, rowsPerPage, sortBy, descending, searchTxt) => {
    if (contentsId !== '') {
      setLoading(true)
      const res = await API.get(`/api/boards/${boardId.trim()}/contents/${contentsId}/logs`, {
        params: {
          page,
          rowsPerPage,
          sortBy,
          descending,
          searchTxt
        }
      }).catch( () => {
        return {
          data: []
        }
      })
      setData(res.data)
      if (res.pageable) {
        setRowCount(res.pageable.rowsNumber)
      }
      setLoading(false)
    } else {
      setData([])
    }
  }, [boardId, contentsId])

  useEffect(() => {
    if (open === true) {
      selectList(pagination.pageIndex, pagination.pageSize, sorting[0].id, sorting[0].desc, globalFilter)
    }
  }, [sorting, pagination.pageIndex, pagination.pageSize, selectList, globalFilter, open])

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'userId',
        header: '아이디',
        size: 100,
      },
      {
        accessorKey: 'userName',
        header: '이름'
      },
      {
        accessorKey: 'deptName',
        header: '소속명'
      },
      {
        accessorKey: 'regDate',
        header: '조회 일시',
        sortDescFirst: true
      },
    ]
  }, [])

  const creditTable = useMaterialReactTable({
    ...tablePageConfig,
    columns,
    data,
    rowCount,
    state: {
      sorting,
      isLoading,
      showProgressBars: isLoading,
      globalFilter,
      pagination
    },
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting
  })

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
      open={open}
      size='small'
      style={{ background: 'none', boxShadow: 'unset' }}
      onClose={() => {return closeModel()}}
    >
      <Modal.Header>조회 기록</Modal.Header>
      <Modal.Content>
        <div className="tablet computer column">
          <MaterialReactTable table={creditTable} />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={() => {
          return closeModel()
        }}>닫기</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LogList

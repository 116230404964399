import React, { useEffect } from 'react'
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { authClear, setAuth } from '@/store/authSlice'
import API from '../../util/api'
import { setMenuInfo } from '@/store/menuSlice'

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    dispatch(authClear())
  }, [dispatch])

  const goMenu = async () => {
    await API.get('/api/menus/first').then(({ data }) => {
      dispatch(setMenuInfo({ ...data, first: true }))
      navigate(data.url)
    }).catch(() => {
    })
  }

  const  onSubmit = async (form) => {
    await API.post('/api/login', form).then(({ data }) => {
      dispatch(setAuth(data))
      goMenu()
    }).catch(() => {
    })
  }


  return (
    <div className="cont_item login_form">
      <div className="login_inner">
        <p>로그인</p>
        <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
          <div className={`field ${errors.username ? 'error' : ''}`}>
            <label htmlFor="u_id" className="hide">
              아이디 입력
            </label>
            <div className="ui input">
              <input
                id="u_id"
                type="text"
                placeholder="아이디를 입력하세요."
                {...register('username', { required: '아이디를 입력하세요.' })}
              />
            </div>
          </div>
          <div className={`field mb0 ${errors.password ? 'error' : ''}`}>
            <label htmlFor="u_pw" className="hide">
              비밀번호 입력
            </label>
            <div className="ui input">
              <input
                id="u_pw"
                type="password"
                placeholder="비밀번호를 입력하세요."
                {...register('password', { required: '비밀번호를 입력하세요.' })}
              />
            </div>
          </div>
          <ul className="link-box">
            <li>
              <div className="ui checkbox">
                <input type="checkbox" id="autoLogin" name="autoLogin" tabIndex="0" className="hidden"/>
                <label htmlFor="autoLogin">ID저장</label>
              </div>
            </li>
          </ul>
          <button type="submit" className="lonin_btn">
            로그인
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login

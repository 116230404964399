import React, { useCallback, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Classic20 } from '@/util/chatColor'
import { getEndDate, getStartDate, isSearching, setSearching } from '@/store/statisticsSlice'
import API from '@/util/api'

const StatMenu = () => {
  const [data, setData] = useState({ labels: '', datasets: [] })
  const dispatch = useDispatch()
  const isSearch = useSelector(isSearching)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)

  const onSearch = useCallback(async () => {
    const res = await API.get('/api/log/menus', {
      params: {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
      }
    }).catch(() => {
      return {
        data: []
      }
    })
    const datasets = {
      labels: res.data.map(m => { return m.str }),
      datasets: [
        {
          data: res.data.map(m => { return m.count }),
          backgroundColor: Classic20
        },
      ]
    }
    setData(datasets)
    dispatch(setSearching(false))
  }, [dispatch, endDate, startDate])

  useEffect( () => {
    if (isSearch === true) {
      onSearch()
    }
  }, [isSearch, onSearch])

  return (
    <>
      <div className="ui attached message flex-item">
        <div className="header">메뉴 이용 통계</div>
        <span className="h_subTitle flex-left-auto">{`범위 : ${moment(startDate).format('YYYY-MM-DD')} ~ ${moment(endDate).format('YYYY-MM-DD')}`}</span>
      </div>
      <div className="ui bottom attached segment">
        <div className="chart-box">
          <div className="chart-container">
            <div style={{ width: '100%', height: '400px' }}>
              <Bar data={data} options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    afterDataLimits: (axis) => {
                      axis.max += 10
                    }
                  }
                },
                plugins: {
                  datalabels: {
                    color: '#36A2EB',
                    align: 'end',
                    anchor: 'end',
                  },
                  legend: {
                    display: false
                  }
                }
              }} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatMenu

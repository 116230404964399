import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import MenuSlice from './menuSlice'
import AuthSlice from './authSlice'
import SnackSlice from './snackSlice'
import BoardSlice from './boardSlice'
import StatisticsSlice from './statisticsSlice'


const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: storageSession,
    whitelist: ['auth', 'menu']
  },
  combineReducers({
    menu: MenuSlice,
    auth: AuthSlice,
    snack: SnackSlice,
    board: BoardSlice,
    statistics: StatisticsSlice
  })
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({ serializableCheck: false })
  },
})

export default store

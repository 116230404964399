import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { getGroupId } from '@/store/menuSlice'
import API from '../../util/api'
import { tableListConfig } from '@/util/table'
import SweetAlert, { confirmFn, deleteCfm } from '@/util/sweetalert'
import { setSnackSuccess } from '@/store/snackSlice'
import { Validate } from '@/util/validate'
import FormSwitch from '../table/FormSwitch'

const GroupMenuList = () => {
  const dispatch = useDispatch()
  const groupId = useSelector(getGroupId)
  const [isLoading, setLoading] = useState(false)
  const [ data, setData ] = useState([])
  const [validationErrors, setValidationErrors] = useState({})

  const selectList = useCallback(async () => {
    if (groupId !== '') {
      setLoading(true)
      const res = await API.get(`/api/groups/${groupId}/menu`).catch( () => {
        return {
          data: []
        }
      })
      setData(res.data)
      setLoading(false)
    } else {
      setData([])
    }
  }, [groupId])

  useEffect(() => {
    selectList()
  }, [selectList])

  const validateCheck = (menu) => {
    return {
      name: !Validate.Required(menu.name) ? '페이지를 입력하세요.' : ''
    }
  }

  const handleCreate = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.post('/api/menus', values).then(() => {
      setValidationErrors({})
      table.setCreatingRow(null)
      dispatch(setSnackSuccess('등록'))
      selectList()
    }).catch(() => {
    })
  }

  const handleEdit = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    const { original } = table.options.state.editingRow
    await API.put(`/api/menus/${original.menuId}`, {
      ...original,
      ...values,
      groupId
    }).then(() => {
      setValidationErrors({})
      table.setEditingRow(null)
      dispatch(setSnackSuccess('수정'))
      selectList()
    }).catch(() => {
    })
  }

  const handleDelete = (row) => {
    deleteCfm('').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/menus/${row.original.menuId}`).then(() => {
          setValidationErrors({})
          dispatch(setSnackSuccess('삭제'))
          selectList()
        }).catch(() => {
        })
      }
    })
  }

  const setMenuOrder = (list, dataList) => {
    dataList.forEach( (el, idx) => {
      list.push(
        {
          id: el.menuId,
          name: el.name,
          orderNo: idx + 1,
          parentId: el.parentId,
          icon: el.icon,
          url: el.url,
          param: el.param
        }
      )
      if (el.subRows) {
        setMenuOrder(list, el.subRows, idx)
      }
    })
    return list
  }
  const handleOrder = () => {
    confirmFn('변경된 순서로 저장하시겠습니까?').then(async (result) => {
      if (result.isConfirmed) {
        const menu = []
        setMenuOrder(menu, data)
        await API.put('/api/menus', menu).then(() => {
          setValidationErrors({})
          dispatch(setSnackSuccess('수정'))
          selectList()
        }).catch(() => {
        })
      }
    })
  }

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'name',
        header: '페이지',
        sortDescFirst: true,
        muiEditTextFieldProps: {
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
        }
      },
      {
        accessorKey: 'icon',
        header: '아이콘',
      },
      {
        accessorKey: 'url',
        header: '메뉴 링크'
      },
      {
        accessorKey: 'param',
        header: '파라미터',
      },
      {
        accessorKey: 'useYn',
        header: '사용',
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.useYn === '') ? true : row.useYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      },
      {
        accessorKey: 'writeYn',
        header: '수정/삭제',
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.writeYn === '') ? true : row.writeYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      }
    ]
  }, [validationErrors])

  const creditTable = useMaterialReactTable({
    ...tableListConfig,
    columns,
    data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    enableHiding: false,
    enableSorting: false,
    enableGlobalFilter: false,
    enableBottomToolbar: false,
    enableRowOrdering: true,
    onCreatingRowCancel: () => {return setValidationErrors({})},
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => {return setValidationErrors({})},
    onEditingRowSave: handleEdit,
    muiRowDragHandleProps: ({ table }) => {
      return {
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState()
          if (draggingRow && hoveredRow) {
            if (!draggingRow.parentId && hoveredRow.parentId === draggingRow.id) {
              SweetAlert.info('부모 메뉴가 하위 메뉴로 이동할 수 없습니다.')
            } else if (!hoveredRow.parentId && draggingRow.parentId) {
              if (hoveredRow.id !== draggingRow.parentId) {
                confirmFn('서브 메뉴로 이동하시겠습니까?').then( (result) => {
                  if (result.isConfirmed) {
                    const source = data.find(f => { return f.menuId === draggingRow.parentId}).subRows.find( f => { return f.menuId === draggingRow.id })
                    source.parentId = hoveredRow.id
                    data.flatMap(m => {
                      if (m.menuId === hoveredRow.id) {
                        if (m.subRows) {
                          m.subRows.push(source)
                        } else {
                          m.subRows = [source]
                        }
                      } else if (m.menuId === draggingRow.parentId) {
                        const sourceIdx = m.subRows.findIndex( fa => {return fa.menuId === draggingRow.id} )
                        m.subRows.splice(sourceIdx, 1)
                      }
                      return m
                    })
                    setData([...data])
                  }
                })
              } else {
                confirmFn('대 메뉴로 이동하시겠습니까?').then( (result) => {
                  if (result.isConfirmed) {
                    const source = data.find(f => { return f.menuId === draggingRow.parentId}).subRows.find( f => { return f.menuId === draggingRow.id })
                    source.parentId = (hoveredRow.parentId) ? hoveredRow.parentId : 'ROOT'
                    data.flatMap(m => {
                      if (m.menuId === draggingRow.parentId) {
                        const sourceIdx = m.subRows.findIndex( fa => {return fa.menuId === draggingRow.id} )
                        m.subRows.splice(sourceIdx, 1)
                      }
                      return m
                    })
                    data.push(source)
                    setData([...data])
                  }
                })
              }
            } else if (hoveredRow.parentId === draggingRow.parentId) {
              if (draggingRow.parentId && hoveredRow.parentId) {
                data.flatMap(m => {
                  if (m.subRows.length > 0 && m.menuId === draggingRow.parentId) {
                    const targetIdx = m.subRows.findIndex( fa => {return fa.menuId === hoveredRow.id} )
                    const sourceIdx = m.subRows.findIndex( fa => {return fa.menuId === draggingRow.id} )
                    m.subRows.splice(targetIdx, 0, m.subRows[sourceIdx])
                    if (targetIdx < sourceIdx) {
                      m.subRows.splice(sourceIdx + 1, 1)
                    } else {
                      m.subRows.splice(sourceIdx, 1)
                    }
                  }
                  return m
                })
                setData([...data])
              } else if (draggingRow.subRows.length > 0) {
                const targetIdx = data.findIndex( fa => {return fa.menuId === hoveredRow.id} )
                const sourceIdx = data.findIndex( fa => {return fa.menuId === draggingRow.id} )
                data.splice(targetIdx, 0, data[sourceIdx])
                if (targetIdx < sourceIdx) {
                  data.splice(sourceIdx + 1, 1)
                } else {
                  data.splice(sourceIdx, 1)
                }
                setData([...data])
              } else {
                confirmFn('서브 메뉴로 이동하시겠습니까?').then( (result) => {
                  const targetIdx = data.findIndex( fa => {return fa.menuId === hoveredRow.id} )
                  const sourceIdx = data.findIndex( fa => {return fa.menuId === draggingRow.id} )
                  if (result.isConfirmed) {
                    const source = data[sourceIdx]
                    data.splice(sourceIdx, 1)
                    source.parentId = data[targetIdx].menuId
                    if (data[targetIdx].subRows) {
                      data[targetIdx].subRows.push(source)
                    } else {
                      data[targetIdx].subRows = [source]
                    }
                  } else {
                    data.splice(targetIdx, 0, data[sourceIdx])
                    if (targetIdx < sourceIdx) {
                      data.splice(sourceIdx + 1, 1)
                    } else {
                      data.splice(sourceIdx, 1)
                    }
                  }
                  setData([...data])
                })
              }
            } else if (!draggingRow.parentId && hoveredRow.parentId) {
              SweetAlert.info('메뉴는 2단계 까지만 가능합니다.')
              /*
              const source = data.find( fa => {return fa.menuId === draggingRow.id} )
              if (source.subRows.length > 0) {
                confirmFn('서브 메뉴로 이동하시겠습니까?').then( (result) => {
                  if (result.isConfirmed) {
                    const sourceIdx = data.findIndex( fa => {return fa.menuId === draggingRow.id} )
                    data.splice(sourceIdx, 1)
                    data.flatMap(m => {
                      if (m.menuId === hoveredRow.parentId) {
                        m.subRows.flatMap(sm => {
                          if (sm.menuId === hoveredRow.id) {
                            if (sm.subRows) {
                              sm.subRows.push(source)
                            } else {
                              sm.subRows = [source]
                            }
                          }
                          return sm
                        })
                      }
                      return m
                    })
                    setData([...data])
                  }
                })
              }
              */
            }
          }
        },
      }
    },
    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="수정">
            <IconButton onClick={() => {
              return table.setEditingRow(row)
            }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="삭제">
            <IconButton color="error" onClick={() => { return handleDelete(row) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    getRowId: (row) => { return row.menuId },
    renderTopToolbarCustomActions: ({ table }) => {return (
      <div>
        <Button
          primary
          onClick={() => {
            table.setCreatingRow(true)
          }}
        >
          메뉴 추가
        </Button>
        <Button
          color="green"
          onClick={handleOrder}
        >
          메뉴 순서 저장
        </Button>
      </div>
    )},
    enableExpandAll: false,
    enableExpanding: true,
    initialState: { expanded: false },
    state: {
      showProgressBars: isLoading
    }
  })

  return (
    <div>
      <MaterialReactTable table={creditTable}/>
    </div>
  )
}

export default GroupMenuList

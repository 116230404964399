import React from 'react'
import { Navigate } from 'react-router-dom'

const AuthRoute = ({ isLogin, authStatus, component: Component }) => {
  // eslint-disable-next-line no-alert
  const message = (authStatus === 'NONE') ?  alert("로그인 후 이용이 가능합니다.") :  {}
  return (
    isLogin ? Component : <Navigate to="/" replace { ...message }  />
  )
}

export default AuthRoute

import React from 'react'
import GroupList from '../../components/menu/GroupList'
import GroupMenuList from '../../components/menu/GroupMenuList'

const Index = () => {

  const titleStyle = {
    flex: 'none'
  }

  return (
    <div className="ui grid stretched row">
      <div className="sixteen wide tablet six wide computer column">
        <div className="ui top attached message" style={titleStyle}>
          <div className="header">권한 그룹 관리</div>
        </div>
        <div className="ui bottom attached segment">
          <GroupList />
        </div>
      </div>
      <div className="sixteen wide tablet ten wide computer column">
        <div className="ui top attached message" style={{ flexGrow: 'inherit' }}>
          <div className="header">메뉴 권한 관리</div>
        </div>
        <div className="ui bottom attached segment">
          <GroupMenuList />
        </div>
      </div>
    </div>
  )
}

export default Index

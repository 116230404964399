import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Input } from 'semantic-ui-react'
import API from '../../util/api'
import { getMenuWriteYn, setMenuButton } from '@/store/menuSlice'
import Contents from '@/components/board/Contents'
import { setBoardInfo } from '@/store/boardSlice'

const List = () => {
  const { boardId } = useParams()
  const dispatch = useDispatch()
  const writeYn = useSelector(getMenuWriteYn)
  const [ searchText, setSearchText ] = useState("")
  const [ search, setSearch ] = useState("")
  const [ totalCount, setTotalCount ] = useState(0)
  const [ pageSize, setPageSize ] = useState(10)

  const selectInfo = useCallback(async () => {
    const res = await API.get(`/api/boards/${boardId}`).catch( () => {
      return {
        data: {}
      }
    })
    dispatch(setBoardInfo(res.data))
  }, [boardId, dispatch])

  useEffect(() => {
    selectInfo()
  }, [selectInfo])

  useEffect(() => {
    if (writeYn === true) {
      dispatch(setMenuButton('board/ListPageBtn'))
    }
  }, [dispatch, writeYn])

  return (
    <div>
      <div className="option-content">
        <span className="totalNum">총 {totalCount}건</span>
        <div className="ui action input search-box">
          <Input placeholder="검색" onChange={(e) => {
            setSearchText(e.target.value)
          }} onKeyPress={ (e) => {
            if (e.key === 'Enter') {
              setSearch(searchText)
            }
          }}/>
          <button type="button" className="ui icon button" onClick={ () => {
            setSearch(searchText)
          }} aria-label="게시글 조회"><i className="search icon"/></button>
        </div>
        <div className="button-area">
          <select className="ui dropdown list-num" onChange={ (e) => {
            setPageSize(e.target.value)
          }}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <Contents boardId={boardId} pageSize={pageSize} search={search} setTotalCount={setTotalCount} />
    </div>
  )
}

export default List

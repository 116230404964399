import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Chart as ChartJS, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import StatLogin from '@/components/statistics/StatLogin'
import { setMenuButton } from '@/store/menuSlice'
import { setClear } from '@/store/statisticsSlice'
import StatMenu from '@/components/statistics/StatMenu'
import TopMenu from '@/components/statistics/TopMenu'
import TopLogin from '@/components/statistics/TopLogin'

ChartJS.register(...registerables, ChartDataLabels)

const Index = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setMenuButton('statistics/IndexPageBtn'))
    dispatch(setClear())
  }, [dispatch])

  return (
    <div className="ui form">
      <div className="ui grid stretched row">
        <div className="sixteen wide tablet eight wide computer column mo-hide">
          <TopMenu />
        </div>
        <div className="sixteen wide tablet eight wide computer column mo-hide">
          <TopLogin />
        </div>
      </div>
      <div className="mo-hide mt20">
        <StatLogin />
      </div>
      <div className="mo-hide mt20">
        <StatMenu />
      </div>
    </div>
  )
}

export default Index

import React from 'react'

const Footer = () => {
  return (
    <footer className="main-class">
      <div className="inner-wrap">
        <div className="footer_logo"><img src="/images/w_swu_logo.svg" alt="혁신공유대학 하단로고"/></div>
        <div className="fr_cont">
          <ul className="copy">
            <li>서울여자대학교 데이터혁신팀</li>
            <li>01797 서울특별시 노원구 화랑로 621 서울여자대학교 데이터혁신실 TEL 02-970-7982 E-MAIL ddit@swu.ac.kr</li>
            <li>COPYRIGHT © 2023. SEOUL WOMEN’S UNIVERSITY. ALL RIGHTS RESERVED.</li>
          </ul>
          <div className="exc_copy">
            <a href="#!" className="ui inverted basic small button">개인정보처리방침</a>
            <a href="#!" className="ui inverted basic small button">자동이메일수집거부</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

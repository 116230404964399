import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const MySwal = withReactContent(Swal)

const config = {
  position: "center",
  customClass: {
    title: 'custom-title-class',
  },
  confirmButtonText: '확인',
  cancelButtonText: "취소"
}

const SweetAlert = {
  'logout': (title, message) => {
    MySwal.fire({
      ...config,
      allowEscapeKey: false,
      icon: "error",
      titleText: title,
      text: message
    }).then( () => {
      window.location.href = '/'
    })
  },
  'success': (message) => {
    MySwal.fire({
      ...config,
      timer: 1500,
      icon: "success",
      titleText: message
    })
  },
  'error': (message) => {
    MySwal.fire({
      ...config,
      icon: "error",
      titleText: message
    })
  },
  'warning': (message) => {
    MySwal.fire({
      ...config,
      timer: 1500,
      icon: "warning",
      titleText: message
    })
  },
  'info': (message) => {
    MySwal.fire({
      ...config,
      timer: 1500,
      icon: "info",
      titleText: message
    })
  }
}

export const deleteCfm = (text) => {
  return MySwal.fire({
    ...config,
    allowEscapeKey: false,
    title: '삭제하시겠습니까?',
    text,
    icon: 'warning',
    showCancelButton: true,
    padding: '2em'
  })
}

export const confirmFn = (title, text) => {
  return MySwal.fire({
    ...config,
    title,
    text,
    allowEscapeKey: false,
    icon: 'info',
    showCancelButton: true,
    padding: '2em'
  })
}

export default SweetAlert

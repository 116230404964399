import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getOpenId, menuToggleStatus, setMenuToggle } from '@/store/menuSlice'
import API from '../../util/api'
import TopMenu from './TopMenu'
import SubMenu from './SubMenu'
import { getDeptId } from '@/store/authSlice'

const MenuList = () => {
  const toggleStatus = useSelector(menuToggleStatus)
  const menuId = useSelector(getOpenId)
  const deptId = useSelector(getDeptId)
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const selectList = useCallback(async () => {
    const res = await API.get('/api/menus').catch(() => {
      return {
        data: [],
      }
    })
    setData(res.data)
  }, [])

  useEffect(() => {
    selectList()
  }, [selectList, deptId])

  const onClick = (e) => {
    e.preventDefault()
    dispatch(setMenuToggle())
  }

  return (
    <div className="gn-trigger">
      <a href="#!" className="gn-icon gn-icon-menu" onClick={onClick}>
        <span>메뉴</span>
      </a>
      <nav className={`gn-menu-wrapper ${toggleStatus ? 'gn-open-all' : ''}`}>
        <div className="gn-scroller">
          <ul className="gn-menu">
            {data.map((info) => {
              return (
                <li key={info.id} className={menuId === info.id ? 'open' : ''}>
                  <TopMenu info={info} />
                  { (data.subCnt === 0) ? null : <SubMenu subRows={info.subRows} />
                  }
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default MenuList

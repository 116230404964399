import React from 'react'

const Footer = () => {
  return (
    <footer id="bottom">
      <div className="section">
        <div className="inner">
          <div className="left_wrap">
            <div className="up_box">
              <div className="footer_logo">
                <a href="#!"><img src="/images/b_logo.svg" alt="서울여자대학교 푸터 로고"/></a>
              </div>
              <ul className="copy">
                <li>
                  <span>주소 :<address>01797 서울특별시 노원구 화랑로 621 서울여자대학교 데이터혁신실</address></span>
                  <span>TEL 02-970-7982</span>
                  <span>E-MAIL ddit@swu.ac.kr</span>
                </li>
                <li className="copy_wr">COPYRIGHT(C) 2023. SEOUL WOMEN’S UNIVERSITY. ALL RIGHTS RESERVED.</li>
              </ul>
              <div className="footer_agency">
                <a href="#!" className="privacy_btn" title="">개인정보처리방침</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Button } from 'semantic-ui-react'
import GroupsIcon from '@mui/icons-material/Groups'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch } from 'react-redux'
import FormSwitch from '../table/FormSwitch'
import API from '../../util/api'
import { tableListConfig } from '@/util/table'
import { deleteCfm } from '@/util/sweetalert'
import { setSnackSuccess } from '@/store/snackSlice'
import GroupDept from './GroupDept'
import { setGroupId } from '@/store/menuSlice'
import { Validate } from '@/util/validate'

const GroupList = () => {
  const dispatch = useDispatch()
  const [ data, setData ] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [editGroupId, setEditGroupId] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const [rowSelection, setRowSelection] = useState({})

  const selectList = useCallback(async () => {
    setLoading(true)
    const res = await API.get('/api/groups').catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
    if (res.data.length > 0) {
      const group = res.data[0]
      const selection = {}
      selection[group.id] = true
      setRowSelection(selection)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    selectList()
  }, [selectList])

  useEffect(() => {
    const ids = Object.keys(rowSelection)
    if (ids.length > 0) {
      dispatch(setGroupId(ids[0]))
    }
  }, [dispatch, rowSelection])


  const validateCheck = (menu) => {
    return {
      name: !Validate.Required(menu.name) ? '그룹명을 입력하세요.' : ''
    }
  }

  const handleCloseModel = () => {
    setEditGroupId('')
    selectList()
  }

  const handleCreate = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.post('/api/groups', values).then(() => {
      setValidationErrors({})
      table.setCreatingRow(null)
      dispatch(setSnackSuccess('등록'))
      selectList()
    }).catch(() => {
    })
  }

  const handleEdit = async ({ values, table }) => {
    const newValidationErrors = validateCheck(values)
    if (Object.values(newValidationErrors).some((error) => { return error })) {
      setValidationErrors(newValidationErrors)
      return
    }
    await API.put(`/api/groups/${values.id}`, values).then(() => {
      setValidationErrors({})
      table.setEditingRow(null)
      dispatch(setSnackSuccess('수정'))
    }).catch(() => {
    })
  }

  const handleDelete = (row) => {
    deleteCfm('').then(async (result) => {
      if (result.isConfirmed) {
        await API.delete(`/api/groups/${row.original.id}`).then(() => {
          setValidationErrors({})
          dispatch(setSnackSuccess('삭제'))
          selectList()
        }).catch(() => {
        })
      }
    })
  }

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'name',
        header: '권한그룹명',
        sortDescFirst: true,
        size: 80,
        muiEditTextFieldProps: {
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
        }
      },
      {
        accessorKey: 'id',
        header: '권한그룹코드',
        enableEditing: false,
        size: 50
      },
      {
        accessorKey: 'deptCnt',
        header: '소속 수',
        enableEditing: false,
        size: 30
      },
      {
        accessorKey: 'useYn',
        header: '사용여부',
        size: 30,
        Cell: ({ cell }) => {
          return (cell.getValue()) ? '사용' : '미사용'
        },
        accessorFn: (row) => { return (row.useYn === '') ? true : row.useYn },
        Edit: (props) => {
          return FormSwitch(props)
        },
      },
    ]
  }, [validationErrors])

  const creditTable = useMaterialReactTable({
    ...tableListConfig,
    columns,
    data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    enableRowSelection: (row) => {
      return (row.id !== 'mrt-row-create')
    },
    enableMultiRowSelection: false,
    enableBottomToolbar: true,
    onCreatingRowCancel: () => {return setValidationErrors({})},
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => {return setValidationErrors({})},
    onEditingRowSave: handleEdit,
    getRowId: (row) => { return row.id },
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: row.getToggleSelectedHandler(),
        sx: { cursor: 'pointer' }
      }
    },
    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="소속 추가">
            <IconButton color="primary" onClick={() => {
              return setEditGroupId(row.id)
            }}>
              <GroupsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="수정">
            <IconButton onClick={() => {
              return table.setEditingRow(row)
            }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="삭제">
            <IconButton color="error" onClick={() => { return handleDelete(row) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    renderTopToolbarCustomActions: ({ table }) => {return (
      <Button
        primary
        onClick={() => {
          table.setCreatingRow(true)
        }}
      >
        권한 그룹 추가
      </Button>
    )},
    enableGlobalFilter: false,
    enablePagination: true,
    manualSorting: false,
    onRowSelectionChange: setRowSelection,
    positionToolbarAlertBanner: 'none',
    state: {
      isLoading,
      rowSelection,
      showProgressBars: isLoading
    }
  })

  return (
    <div>
      <MaterialReactTable table={creditTable}/>
      <GroupDept open={editGroupId !== ''} groupId={editGroupId} closeModel={handleCloseModel}/>
    </div>
  )
}

export default GroupList

import React, { useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import "@/assets/css/h_main.css"
import "@/assets/css/h_footer.css"
import Slider from "react-slick"
import { useDispatch } from 'react-redux'
import Login from '@/components/home/Login'
import Footer from '@/components/home/Footer'
import { setMenuClear } from '@/store/menuSlice'

const HomeIndex = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setMenuClear())
    const iframes = document.getElementsByClassName('quicksight-embedding-iframe')
    if (iframes.length > 0) {
      Array.from(iframes).forEach((iframe) => { iframe.remove() })
    }
  }, [dispatch])

  const settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    dots: true,
  }


  return (
    <div>
      <div id="home_container" className="body__container pusher">
        <div className="section visual">
          <Slider {...settings}>
            <div className="slide">
              <img src="/images/new_slide01.avif" alt="슬라이드이미지1"/>
            </div>
            <div className="slide">
              <img src="/images/new_slide02.avif" alt="슬라이드이미지2"/>
            </div>
            <div className="slide">
              <img src="/images/new_slide03.avif" alt="슬라이드이미지3"/>
            </div>
          </Slider>
          <div className="overlay"/>
          <div className="inner">
            <div className="v_logo"/>
            <div className="cont_box">
              <div className="cont_item visual_txt">
                <p className="en_txt">SEOUL WOMEN&apos;S UNIVERSITY</p>
                <p className="en_sub_txt">Cloud-based Data Integration and Analysis Platform</p>
                <p className="kr_txt">Innovate with Data, Lead with Insights</p>
              </div>
              <Login/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomeIndex

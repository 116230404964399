import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: {
    name: '',
    noticeYn: false
  },
  id: '',
  isSubmit: false,
  isEdit: false,
}

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    setBoardInfo: (state, action) => {
      state.info = action.payload
      state.id = action.payload.id
    },
    setSubmit: (state, action) => {
      state.isSubmit = action.payload
    },
    setEdit: (state, action) => {
      state.isEdit = action.payload
    }
  }
})

export const { setBoardInfo, setSubmit, setEdit } = boardSlice.actions

export const getBoardInfo = (state) => { return state.board.info }
export const getBoardId = (state) => { return state.board.id }
export const getSubmit = (state) => { return state.board.isSubmit }
export const getEdit = (state) => { return state.board.isEdit }

export default boardSlice.reducer

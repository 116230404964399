import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '@/util/api'

export const setMenuInfo = createAsyncThunk(
  'menu/setMenuInfo',
  async (menu) => {
    const logMenu = {
      menuId: menu.id,
      menuName: menu.title,
    }
    await API.post('/api/log/menus', logMenu).catch(() => {})
    return menu
  }
)

const initialState = {
  isOpen: true,
  info: {
    id: '',
    title: '',
    url: '',
    param: '',
    writeYn: false
  },
  firstMenuInfo: {
    id: '',
    parentId: '',
    title: '',
    url: '',
    param: '',
    writeYn: false
  },
  openId: '',
  groupId: '',
  buttons: null
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuToggle: (state) => {
      state.isOpen = !state.isOpen
    },
    setOpenId: (state, action) => {
      state.openId = (state.openId === action.payload) ? '' : action.payload
    },
    setMenuClear: (state) => {
      state.firstMenuInfo = {}
      state.info = {}
      state.openId = ''
      state.buttons = null
    },
    setMenuTitle: (state, action) => {
      state.info.title = action.payload
    },
    setMenuButton: (state, action) => {
      state.buttons = (action.payload) ? action.payload : null
    },
    setGroupId: (state, action) => {
      state.groupId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMenuInfo.pending, (state) => {
      state.buttons = null
    }).addCase(setMenuInfo.fulfilled, (state, action) => {
      if (action.payload.first) {
        state.firstMenuInfo.id = action.payload.id
        state.firstMenuInfo.parentId = action.payload.parentId
        state.firstMenuInfo.title = action.payload.title
        state.firstMenuInfo.param = action.payload.param
        state.firstMenuInfo.writeYn = action.payload.writeYn
        state.firstMenuInfo.url = action.payload.url
        state.openId = action.payload.parentId
      }
      state.info.id = action.payload.id
      state.info.title = action.payload.title
      state.info.param = action.payload.param
      state.info.url = action.payload.url
      state.info.writeYn = action.payload.writeYn
    })
  },
})

export const { setMenuToggle, setOpenId, setMenuClear,
  setMenuTitle, setMenuButton, setGroupId } = menuSlice.actions

export const menuToggleStatus = (state) => { return state.menu.isOpen }
export const getMenuTitle = (state) => { return state.menu.info.title }
export const getMenuId = (state) => { return state.menu.info.id }
export const getOpenId = (state) => { return state.menu.openId }
export const getParam = (state) => { return state.menu.info.param }
export const getMenuTitleBtn = (state) => { return state.menu.buttons }
export const getMenuWriteYn = (state) => { return state.menu.info.writeYn }
export const getGroupId = (state) => { return state.menu.groupId }
export const getFirstMenuInfo = (state) => { return state.menu.firstMenuInfo }
export default menuSlice.reducer

import React, { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { tablePageConfig } from '@/util/table'
import API from '@/util/api'

const LogList = () => {
  const [ data, setData ] = useState([])
  const [ rowCount, setRowCount ] = useState(0)
  const [sorting, setSorting] = useState([
    {
      id: 'startDate',
      desc: true
    }
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [globalFilter, setGlobalFilter] = useState('')
  const [isLoading, setLoading] = useState(false)

  const selectList = async (page, rowsPerPage, sortBy, descending, searchTxt) => {
    setLoading(true)
    const res = await API.get('/api/sync-logs', {
      params: {
        page,
        rowsPerPage,
        sortBy,
        descending,
        searchTxt
      }
    }).catch( () => {
      return {
        data: []
      }
    })
    setData(res.data)
    if (res.pageable) {
      setRowCount(res.pageable.rowsNumber)
    }
    setLoading(false)
  }

  useEffect(() => {
    selectList(pagination.pageIndex, pagination.pageSize, sorting[0].id, sorting[0].desc, globalFilter)
  }, [sorting, globalFilter, pagination.pageIndex, pagination.pageSize])

  const columns = useMemo( () => {
    return [
      {
        accessorKey: 'id',
        header: '아이디',
        sortDescFirst: true
      },
      {
        accessorKey: 'name',
        header: '모듈명'
      },
      {
        accessorKey: 'description',
        header: '설명',
      },
      {
        accessorKey: 'startDate',
        header: '시작일시'
      },
      {
        accessorKey: 'endDate',
        header: '종료일시'
      },
      {
        accessorFn: (row) => { return row.stateName },
        id: 'state',
        header: '상태',
      },
      {
        accessorKey: 'message',
        header: '내용'
      },
      {
        accessorKey: 'total',
        header: '전체 건수'
      },
      {
        accessorKey: 'insert',
        header: '등록 건수'
      },
      {
        accessorKey: 'update',
        header: '수정 건수'
      },
      {
        accessorKey: 'delete',
        header: '삭제 건수'
      }
    ]
  }, [])

  const creditTable = useMaterialReactTable({
    ...tablePageConfig,
    columns,
    data,
    rowCount,
    state: {
      sorting,
      isLoading,
      showProgressBars: isLoading,
      globalFilter,
      pagination
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting
  })

  return (
    <div>
      <MaterialReactTable table={creditTable} />
    </div>
  )
}

export default LogList

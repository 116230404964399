import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { menuToggleStatus } from '@/store/menuSlice'
import Header from './Header'
import Footer from './Footer'
import '@/assets/css/element-ui.css'
import '@/assets/css/ionicons.css'
import '@/assets/css/coss-class-default.css'
import ContainerTitle from '../common/ContainerTitle'
import SnackAlert from '../common/SnackAlert'

const Layout = () => {
  const toggleStatus = useSelector(menuToggleStatus)
  return (
    <div id="wrap" className="pusher">
      <Header />
      <div id="container" className={toggleStatus ? '' : 'fold'}>
        <div className="content">
          <div id="context">
            <ContainerTitle />
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
      <SnackAlert />
    </div>
  )
}


export default Layout

import 'semantic-ui-css/semantic.min.css'
import './assets/css/reset.css'
import './assets/css/sweetAlert.css'
import './assets/css/data-grid.css'
import './assets/css/editor.css'
import React from "react"
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeIndex from './pages/home/Index'
import NotFound from './pages/common/NotFound'
import MainIndex from './pages/main/Index'
import UserIndex from './pages/user/Index'
import DeptIndex from './pages/dept/Index'
import MenuIndex from './pages/menu/Index'
import QuicksightIndex from './pages/quicksight/Index'
import BoardInfo from './pages/board/Info'
import BoardList from './pages/board/List'
import BoardForm from './pages/board/Form'
import BoardView from './pages/board/View'
import Setting from './pages/sync/Setting'
import LogList from './pages/sync/LogList'
import StatIndex from './pages/statistics/Index'
import { getAuthStatus, getIsLogin } from './store/authSlice'
import AuthRoute from './util/authRoute'
import Layout from './components/layout/Layout'

function App () {
  const isLogin = useSelector(getIsLogin)
  const authStatus = useSelector(getAuthStatus)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <HomeIndex /> } />
        <Route element={ <AuthRoute isLogin={isLogin} authStatus={authStatus} component={ <Layout /> } /> }>
          <Route path="main" end element={ <MainIndex /> } />
          <Route path="user" end element={ <UserIndex /> } />
          <Route path="dept" end element={ <DeptIndex /> } />
          <Route path="menu" end element={ <MenuIndex /> } />
          <Route path="statistics" end element={ <StatIndex /> } />
          <Route path="board">
            <Route path="" end element={ <BoardInfo /> } />
            <Route path=":boardId/list" element={ <BoardList /> } />
            <Route path=":boardId/form" element={ <BoardForm /> } />
            <Route path=":boardId/view/:id" element={ <BoardView /> } />
          </Route>
          <Route path="sync">
            <Route path="setting" end element={ <Setting /> } />
            <Route path="log" end element={ <LogList /> } />
          </Route>
          <Route path="quicksight" end element={ <QuicksightIndex /> } />
        </Route>
        <Route path="/*" element={ <NotFound /> } />
      </Routes>
    </BrowserRouter>
  )
}

export default App
